
import DropdownCss from '~/components/ui/DropDownCss'
export default {
  name: 'LanguageSelector',
  components: {
    DropdownCss,
    Icon: () => import(/* webpackChunkName: "Icon" */ '~/components/ui/icon/Icon')
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    width: {
      default: 100
    }
  },
  data: () => ({
    isDropdownOpen: false,
    language: {
      en: 'English',
      bn: 'বাংলা'
    }
  }),
  computed: {
    locales () { return this.$i18n.locales.filter(locale => !locale.disabled) },
    selectedLocale () {
      return this.locales.find(locale => this.$i18n.locale === locale.code)
    }
  },
  methods: {
    changeLanguage (code) {
      const url = this.switchLocalePath(code)
      location.href = url.includes('?') ? url.split('?')[0] : url
      this.hideDropdown()
    },
    toggleDropdown () {
      this.isDropdownOpen ? this.hideDropdown() : this.showDropdown()
    },
    showDropdown () {
      this.$refs.dropdown.style.height = ((this.locales.length * 41) + 28) + 'px'
      this.$refs.dropdown.style.boxShadow = '0 15px 50px rgba(0, 0, 0, 0.25)'
      this.$refs.dropdown.style.padding = '14px 0'
      this.$refs.dropdown.style.transition = '0.2s ease-out height, step-start box-shadow 0.2s'
      this.$refs.dropdown.style['-moz-transition'] = '0.2s ease-out height, step-start box-shadow 0.2s'
      this.$refs.dropdown.style['-webkit-transition'] = '0.2s ease-out height, step-start box-shadow 0.2s'
      this.isDropdownOpen = true
    },
    hideDropdown () {
      if (!this.$refs.dropdown) { return }
      setTimeout(() => {
        this.$refs.dropdown.style.height = 0 + 'px'
        this.$refs.dropdown.style.boxShadow = 'none'
        this.$refs.dropdown.style.padding = '0'
        this.isDropdownOpen = false
      }, 150)
    }
  }
}
